<div class="page-heading">
  <div class="heading-l">
    <div class="title">
      <a class="back color-primary" (click)="utils.goBack()"
        ><i class="fa fa-chevron-left"></i
      ></a>
    </div>
  </div>
  <div class="heading-l heading-c">
    <div class="title font-extraBold">{{ "Track Order" | translate }}</div>
  </div>
  <div class="heading-r"></div>
</div>
<div iknLoader [isActive]="isLoading" class="loader-container"></div>
<div class="page-content">
  <div class="checkout-block">
    <div class="form-group" *ngIf="!isLoading">
      <div class="form-group-body">
        <div class="margin-top-10"></div>
        <div
          class="text-center"
          *ngIf="
            order?.status != 'PendingPayment' &&
            order?.status != 'PaymentRejected'
          "
        >
          <!-- <img src="../../assets/images/tick-icon.png" alt="Order Submitted Successfully" class="success-img"/> -->
          <img
            src="../../assets/images/OrderSuccess.svg"
            alt="Order Submitted Successfully"
            class="success-img"
          />
          <!-- <div class="margin-top-10"></div> -->
          <h4 class="no-margin font-extraBold">
            {{ "Thank You!" | translate }}
          </h4>
          <div class="margin-top-10"></div>
          <h3>
            {{ order?.pickupLocation.contactName || order?.customerName }}
          </h3>
          <h5 class="reversed-phone">
            {{ order?.pickupLocation.contactPhone || order?.customerPhone }}
          </h5>
          <div class="margin-top-10"></div>
          <p class="no-margin order-id font-bold">
            {{ "Your order ID" | translate }}
          </p>
          <div class="margin-top-10"></div>
          <!-- <h3 class="no-margin font-weight-400 f-s-18">{{'Your Tracking Number is' | translate}} {{order?.orderNo}}</h3> -->
          <h2 class="no-margin font-extraBold">{{ order?.orderNo }}</h2>

          <p class="no-margin f-s-22 order-placed font-bold">
            {{ "has been placed successfully" | translate }}
          </p>
          <!-- <div style="height: 30px"></div> -->
          <h4 class="font-extraBold text-success">
            {{
              (order?.serviceType == "delivery"
                ? "Expected Delivery Time"
                : "Expected Pickup Time"
              ) | translate
            }}
          </h4>
          <h2 class="margin-top-10">
            {{ "Around" | translate }}
            &nbsp;{{
              order?.pickupTime || order?.expectedDeliveryTime | iknDate : "df"
            }}
            <span *ngIf="!dateOnly">
              &nbsp;{{
                order?.pickupTime || order?.expectedDeliveryTime
                  | iknDate : "tf"
              }}
              <span
                *ngIf="order?.pickupTimeTo || order?.expectedDeliveryTimeTo"
              >
                -
                {{
                  order?.pickupTimeTo || order?.expectedDeliveryTimeTo
                    | iknDate : "tf"
                }}
              </span>
            </span>
          </h2>
          <div *ngIf="order?.serviceType == 'pickup'">
            <h4 class="font-extraBold">{{ "Pickup Location" | translate }}</h4>
            <h4>{{ order?.branch?.name | iknLocalized }}<br /></h4>
            <h4>
              <a
                target="blank"
                [href]="
                  'https://www.google.com/maps/search/?api=1&query=' +
                  order?.branch?.location?.location?.lat +
                  ',' +
                  order?.branch?.location?.location?.lng
                "
              >
                {{ order?.branch?.location?.address | iknLocalized }}
                <i class="text-success fa fa-map-marker"></i
              ></a>
            </h4>
          </div>
          <!-- <div style="height: 40px"></div> -->
          <h1 class="no-margin order-screen font-bold">
            {{ "Take a screenshot from this page" | translate }}
          </h1>
          <h1 class="no-margin order-screen font-bold">
            {{ "so you can track your order anytime" | translate }}
          </h1>
          <div class="margin-top-10"></div>
          <img
            src="../../assets/images/screenshot.png"
            alt="Order Submitted Successfully"
            class="screen-shot"
          />
          <!-- <a class="track-link" [routerLink]="['/track-order']" [queryParams]="{ orderNo: order?.orderNo }">{{'Press here to track your order' | translate}}</a> -->
        </div>
        <div
          class="text-center"
          *ngIf="
            order?.status == 'PendingPayment' ||
            order?.status == 'PaymentRejected'
          "
        >
          <img
            src="../../assets/images/warning.png"
            alt="Order Not Completed"
            class="success-img"
          />
          <div class="margin-top-10"></div>
          <h2 class="no-margin font-bold">
            {{ "Your Order is not completed" | translate }}
          </h2>
          <h2 class="font-weight-500 no-margin f-s-22">
            <h3 class="no-margin font-weight-400 f-s-18 font-bold">
              {{ "Your Order Number is" | translate }} {{ order?.orderNo }}
            </h3>
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="page-btn font-bold"
  [@slideInUpOnEnter]
  *ngIf="
    !isLoading &&
    order?.status != 'PendingPayment' &&
    order?.status != 'PaymentRejected'
  "
>
  <a
    class="btn btn-primary text-capital"
    [routerLink]="['/track-order']" [state]="{ orderNo: order?.orderNo }"
    skipLocationChange="true"
    replaceUrl="true"
  >
    {{ "Track Your Order" | translate }}
  </a>
  <app-footer></app-footer>
</div>
